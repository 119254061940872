import Rails from "@rails/ujs"

$(function() {
  $('.ransack-select').on('change', function() {
    Rails.fire($(this).closest("form")[0], 'submit');
  });

  $('.ransack-input').on('keyup', function() {
    Rails.fire($(this).closest("form")[0], 'submit');
  });
})