import "../packs/jquery.jpostal";

$(function() {
  $('#mukaepostcode').jpostal({
    postcode: [
      '#mukaepostcode'
    ],
    address: {
      "#add1mukae" : "%3%4%5",
      "#add2mukae" : "%6%7"
    }
  });

  $('#okuripostcode').jpostal({
    postcode: [
      '#okuripostcode'
    ],
    address: {
      "#add1okuri" : "%3%4%5",
      "#add2okuri" : "%6%7"
    }
  });
});